import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  InputAdornment,
  Button,
  ButtonGroup,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
// import actionsSensors from "../../../../redux/actions/sensors";

import Axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export default function ({ setViewCreate }) {
  const utils = useSelector((state) => state.utils);

  const [locals, setLocals] = React.useState([]);
  const [formData, setFormData] = React.useState({
    model: "empty",
    imei: 0,
    operator: "",
    sim: 0,
    localID: "",
    material: "PLASTIC",
    containerHeight: 90,
    fillLimitPercentage: 60,
  });

  React.useEffect(() => {
    Axios.get(apiUrl + "/adminTool/removals/getDataCreateRemoval", {
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
    }).then((response) => {
      setLocals(response.data.locals);
    });
  }, []);

  const updateFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSuccess = () => {
    Axios.post(
      apiUrl + "/adminTool/sensors",
      { ...formData },
      {
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      console.log("data enviada");
    });
  };

  const handleChangeLocal = (e) => {
    if (e === "" || e === 0 || e === null || e === undefined) {
      //Control de excepciones
    } else {
      updateFormData({ target: { value: e._id, name: "localID" } });
    }
  }

  return (
    <Dialog open={true} onClose={() => setViewCreate(false)}>
      <DialogContent>
        <Typography variant="h5">Registrar sensor</Typography>
        <hr></hr>
        <Typography variant="h6">Información del sensor</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Modelo"
              variant="outlined"
              fullWidth
              value={formData.model}
              onChange={(e) => updateFormData(e)}
              name="model"
              select
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="EMPTY">Ninguno</MenuItem>
              <MenuItem value="CIRC10">Cirqueen 1.0</MenuItem>
              {/* <MenuItem value="CIRC20">Cirqueen 2.0</MenuItem> */}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="IMEI"
              variant="outlined"
              fullWidth
              value={parseInt(formData.imei, 0)}
              onChange={(e) => updateFormData(e)}
              name="imei"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Operador"
              variant="outlined"
              fullWidth
              value={formData.operator}
              onChange={(e) => updateFormData(e)}
              name="operator"
              type="text"
              select
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="">Ninguno</MenuItem>
              <MenuItem value="ENTEL">Entel</MenuItem>
              <MenuItem value="MOVISTAR">Movistar</MenuItem>
              <MenuItem value="MCI  ">MCI</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Sim card"
              variant="outlined"
              fullWidth
              value={parseInt(formData.sim, 0)}
              onChange={(e) => updateFormData(e)}
              name="sim"
              // type="text"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <hr></hr>
        <Typography variant="h6">Configuración del sensor</Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              options={locals}
              freeSolo
              getOptionLabel={option => option.customerID.brand + " / " + option.name}
              onSelect={(event, newValue) => {
                handleChangeLocal(newValue);
              }}
              onChange={(event, newValue) => {
                handleChangeLocal(newValue);
              }}
              renderInput={(params) =>
                <TextField {...params}
                  margin="dense"
                  fullWidth
                  name="local"
                  label="Local"
                  value={formData.localID}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }} />}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Material que mide"
              variant="outlined"
              fullWidth
              value={formData.material}
              onChange={(e) => updateFormData(e)}
              name="material"
              select
              InputLabelProps={{
                shrink: true,
              }}
            >
              {Object.keys(utils.materials).map((material) => (
                <MenuItem
                  key={"material_" + material}
                  value={material.toUpperCase()}
                >
                  {utils.materials[material].name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Altura del contenedor"
              variant="outlined"
              fullWidth
              value={parseInt(formData.containerHeight, 0)}
              onChange={(e) => updateFormData(e)}
              name="containerHeight"
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Porcentaje de aviso de llenado"
              variant="outlined"
              fullWidth
              value={formData.fillLimitPercentage}
              onChange={(e) => updateFormData(e)}
              name="fillLimitPercentage"
              select
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={50}>50%</MenuItem>
              <MenuItem value={60}>60%</MenuItem>
              <MenuItem value={70}>70%</MenuItem>
              <MenuItem value={80}>80%</MenuItem>
              <MenuItem value={90}>90%</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <DialogActions>
          <ButtonGroup size="small" variant="contained">
            <Button color="primary" onClick={() => setViewCreate(false)}>
              Salir
            </Button>
            <Button color="secondary" onClick={() => handleSuccess()}>
              Guardar
            </Button>
          </ButtonGroup>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
