import React, { useEffect, useState, Fragment } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  Button,
  TextField,
  Grid,
  InputAdornment,
  MenuItem,
} from "@material-ui/core/";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import removalsActions from "../../../redux/actions/removals";

export default function SearchBar() {
  const dispatch = useDispatch();

  const [firstParam, setFirstParam] = useState("");
  const [secondParam, setSecondParam] = useState("");
  const [typeSearch, setTypeSearch] = useState("ALL");

  useEffect(() => {
    if (
      typeSearch === "DATETIME_REQUEST" ||
      typeSearch === "DATETIME_REMOVAL"
    ) {
      setFirstParam(new Date());
      setSecondParam(new Date());
    } else if (typeSearch === "PAYMENT") {
      setFirstParam(0);
      setSecondParam(0);
    } else {
      setFirstParam("");
    }
  }, [typeSearch]);

  useEffect(() => {
    if (typeSearch === "PAYMENT") {
    }
  }, [firstParam, secondParam]);

  const searchOnClick = () => {
    var data = null;
    if (typeSearch === "LOCAL") {
      data = {
        type: "LOCAL",
        firstParam,
      };
    }
    if (typeSearch === "TRANSPORTER") {
      data = {
        type: "TRANSPORTER",
        firstParam,
      };
    }
    if (typeSearch === "DATETIME_REQUEST") {
      data = {
        type: "DATETIME_REQUEST",
        firstParam: {
          datetimeRequest: { $gte: firstParam, $lte: secondParam },
        },
      };
    }
    if (typeSearch === "DATETIME_REMOVAL") {
      data = {
        type: "DATETIME_REMOVAL",
        firstParam: {
          datetimeRemoval: { $gte: firstParam, $lte: secondParam },
        },
      };
    }
    if (typeSearch === "PAYMENT") {
      data = {
        type: "PAYMENT",
        firstParam: {
          payment: { $gte: firstParam, $lte: secondParam },
        },
      };
    }
    if (typeSearch === "STATUS") {
      data = {
        type: "STATUS",
        firstParam: {
          status: firstParam,
        },
      };
    }
    if (typeSearch === "ALL") {
      data = {
        type: "ALL",
        firstParam: {},
      };
    }
    console.log(data);
    dispatch(removalsActions.setSearch(data));
  };
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="outlined-required"
            label="Tipo de búsqueda"
            variant="outlined"
            margin="dense"
            type="text"
            value={typeSearch}
            onChange={(e) => setTypeSearch(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            select
            fullWidth
          >
            <MenuItem value="ALL">Todos</MenuItem>
            <MenuItem value="LOCAL">Empresa / Local</MenuItem>
            <MenuItem value="TRANSPORTER">Transportista</MenuItem>
            <MenuItem value="DATETIME_REQUEST">Fecha de solicitud</MenuItem>
            <MenuItem value="DATETIME_REMOVAL">Fecha de retiro</MenuItem>
            <MenuItem value="PAYMENT">Pago</MenuItem>
            <MenuItem value="STATUS">Estado</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={3}>
          {typeSearch === "LOCAL" || typeSearch === "TRANSPORTER" ? (
            <TextField
              id="outlined-required"
              label="Buscar"
              variant="outlined"
              margin="dense"
              type="text"
              onChange={(e) => setFirstParam(e.target.value)}
              value={firstParam}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          ) : typeSearch === "STATUS" ? (
            <TextField
              id="outlined-required"
              label="Buscar"
              variant="outlined"
              margin="dense"
              type="text"
              onChange={(e) => setFirstParam(e.target.value)}
              value={firstParam}
              InputLabelProps={{
                shrink: true,
              }}
              select
              fullWidth
            >
              <MenuItem value="COMPLETE">Completado</MenuItem>
              <MenuItem value="PENDING_TRANS">Sin transportista</MenuItem>
              <MenuItem value="PENDING_PAYMENT">Sin pago</MenuItem>
            </TextField>
          ) : typeSearch === "PAYMENT" ? (
            <TextField
              id="outlined-required"
              label="Desde"
              variant="outlined"
              margin="dense"
              type="number"
              onChange={(e) => setFirstParam(e.target.value * 1)}
              value={firstParam}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          ) : typeSearch === "DATETIME_REQUEST" ||
            typeSearch === "DATETIME_REMOVAL" ? (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                label="Fecha inicial"
                inputVariant="outlined"
                value={firstParam}
                onChange={setFirstParam}
                margin="dense"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          ) : (
            ""
          )}
        </Grid>

        {typeSearch === "DATETIME_REQUEST" ||
        typeSearch === "DATETIME_REMOVAL" ? (
          <Grid item xs={6} sm={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                label="Fecha final"
                inputVariant="outlined"
                value={secondParam}
                onChange={setSecondParam}
                margin="dense"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
        ) : (
          ""
        )}
        {typeSearch === "PAYMENT" ? (
          <Grid item xs={6} sm={3}>
            <TextField
              id="outlined-required"
              label="Hasta"
              variant="outlined"
              margin="dense"
              type="number"
              onChange={(e) => setSecondParam(e.target.value * 1)}
              value={secondParam}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={3}>
          {typeSearch !== "ALL" ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => searchOnClick()}
              style={{ marginTop: 10 }}
              startIcon={<SearchIcon />}
              fullWidth
            >
              Buscar
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <hr></hr>
    </Fragment>
  );
}
